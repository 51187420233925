export default [
  {
    photo: `https://static.vibe.com/files/2019/08/don-cornelius-1566509377-e1566510254526-1536x864.jpg`,
    photoCredit: `Getty Images`,
    title: `A Musical Inspired By 'Soul Train' Could Be Headed To Broadway`,
    company: `VIBE Magazine`,
    date: `August 22, 2019`,
    url: `https://www.vibe.com/2019/08/a-musical-inspired-by-soul-train-could-be-headed-to-broadway`,
  },
  {
    photo: `https://static.vibe.com/files/2019/08/21-savage-genius-bbq-1566508890-1500x844.jpg`,
    photoCredit: `Neilson Barnard/ Getty Images`,
    title: `21 Savage To Headline Genius IQ/BBQ Summer Concert`,
    company: `VIBE Magazine`,
    date: `August 22, 2019`,
    url: `https://www.vibe.com/2019/08/21-savage-genius-iq-summer-concert`,
  },
  {
    photo: `https://static.vibe.com/files/2019/08/women-equal-pay-1566508459-1536x864.jpg`,
    photoCredit: `Getty Images`,
    title: `Black Women's Equal Pay Day Addresses Race And Gender Biases In The Work`,
    company: `VIBE Magazine`,
    date: `August 22, 2019`,
    url: `https://www.vibe.com/2019/08/black-womens-equal-pay-day`,
  },
  {
    photo: `https://static.vibe.com/files/2019/08/sho-madjozi-colors-john-cena-yomzansi-1566486992-650x366.png`,
    photoCredit: `YouTube`,
    title: `Sho Madojozi "John Cena" Sparks Challenge And John Cena Shoutout`,
    company: `VIBE Magazine`,
    date: `August 22, 2019`,
    url: `https://www.vibe.com/2019/08/sho-madojozis-john-cena-sparks-challenge-and-john-cena-shoutout`,
  },
  {
    photo: `https://static.vibe.com/files/2019/08/jermaine-dupri-jay-z-1566498566-1500x844.jpg`,
    photoCredit: `Getty Images`,
    title: `Jermaine Dupri Says Jay-Z Didn't Advise Him To Turn Down NFL Deal`,
    company: `VIBE Magazine`,
    date: `August 22, 2019`,
    url: `https://www.vibe.com/2019/08/jermaine-dupri-jay-z-nfl-deal`,
  },
  {
    photo: `https://static.vibe.com/files/2019/08/clark-atlanta-university-shooting-1566399876-768x432.jpg`,
    photoCredit: `Joe Raedle/ Getty Images`,
    title: `Four Students Shot At Block Party Near Clark Atlanta University Campus`,
    company: `VIBE Magazine`,
    date: `August 21, 2019`,
    url: `https://www.vibe.com/2019/08/clark-atlanta-university-shooting`,
  },
  {
    photo: `https://static.vibe.com/files/2019/08/sza-album-1566332909-768x432.jpg`,
    photoCredit: `Craig Barritt/ Getty Images`,
    title: `SZA Reveals Sophomore Album Is On the Way`,
    company: `VIBE Magazine`,
    date: `August 20, 2019`,
    url: `https://www.vibe.com/2019/08/sza-sophomore-album-on-the-way`,
  },
  {
    photo: `https://static.vibe.com/files/2019/08/big-sean-boys-and-girls-club-1566325065-e1566325299735-768x432.jpg`,
    photoCredit: `Issac Brekken/ Getty Images for iHeartMedia`,
    title: `Big Sean Donates $100K Recording Studio To Detroit Boys And Girls Club`,
    company: `VIBE Magazine`,
    date: `August 20, 2019`,
    url: `https://www.vibe.com/2019/08/big-sean-donates-studio-to-detroit-boys-and-girls-club`,
  },
  {
    photo: `https://static.vibe.com/files/2019/08/GettyImages-947277704-1566317320-669x975.jpg`,
    photoCredit: `Paras Griffin/Getty Images for Codeblack Films`,
    title: `'The Wire's Tray Chaney Shares His Testament In Self-Entitled Documentarry Trailer`,
    company: `VIBE Magazine`,
    date: `August 20,2019`,
    url: `https://www.vibe.com/2019/08/the-wires-tray-chaney-shares-his-testament-in-self-entitled-documentary-trailer`,
  },
  {
    photo: `https://static.vibe.com/files/2019/08/nipsey-hussle-puma-1566313667-752x975.jpg`,
    photoCredit: `Jerritt Clark/Getty Images for PUMA`,
    title: `Nipsey Hussle's Team Unveils Puma Collaboration Release Date`,
    company: `VIBE Magazine`,
    date: `August 19, 2019`,
    url: `https://www.vibe.com/2019/08/nipsey-hussle-puma-release-date`,
  },
  {
    photo: `https://static.vibe.com/files/2019/08/young-thug-yfn-lucci-1566240991-1536x864.png`,
    photoCredit: `Getty Images`,
    title: `Young Thug Addresses YFN Lucci's Comments After 'So Much Fun' Release`,
    company: `VIBE Magazine`,
    date: `August 19, 2019`,
    url: `https://www.vibe.com/2019/08/young-thug-responds-to-yfn-luccis-comments-after-so-much-fun-release`,
  },
  {
    photo: `https://res.cloudinary.com/areese/image/upload/v1566153632/VIBE/Jidenna%20%22Tribe%22/Wale%20%22On%20Chill%22/wale-on-chill.png`,
    photoCredit: `YouTube`,
    title: `WATCH: Wale Drops Tantalizing And Alluring Visual For "On Chill" Feat. Jeremih`,
    company: `VIBE Magazine`,
    date: `August 15, 2019`,
    url: `https://www.vibe.com/2019/08/wale-on-chill-video`,
  },
  {
    photo: `https://res.cloudinary.com/areese/image/upload/v1566155378/VIBE/Jidenna%20%22Tribe%22/Ari%20Lennox%20%22BMO%22/ari-lennox-bmo.png`,
    photoCredit: `YouTube`,
    title: `WATCH: Ari Lennox Unveils Sizzling New Visual For "BMO"`,
    company: `VIBE Magazine`,
    date: `August 6, 2019`,
    url: `https://www.vibe.com/2019/08/ari-lennox-bmo-music-video`,
  },
  {
    photo: `https://res.cloudinary.com/areese/image/upload/v1566151286/VIBE/Jidenna%20%22Tribe%22/jidenna-tribe-music-video.png`,
    photoCredit: `YouTube`,
    title: `WATCH: Jidenna's "Tribe" Video Is A Head-Spinning Party`,
    company: `VIBE Magazine`,
    date: `August 1, 2019`,
    url: `https://www.vibe.com/2019/08/jidenna-tribe-music-video`,
  },
  {
    photo: `https://res.cloudinary.com/areese/image/upload/v1566155034/VIBE/Jidenna%20%22Tribe%22/Chris%20Brown%20%22No%20Guidance%22/chris-brown-no-guidance.png`,
    photoCredit: `YouTube`,
    title: `WATCH: Chris Brown And Drake Drop "No Guidance" Visual`,
    company: `VIBE Magazine`,
    date: `July 26, 2019`,
    url: `https://www.vibe.com/2019/07/chris-brown-drake-no-guidance-video`,
  },
  {
    photo: `https://res.cloudinary.com/areese/image/upload/v1566154831/VIBE/Jidenna%20%22Tribe%22/Masego%20NPR/masego-npr-tiny-desk.png`,
    photoCredit: `NPR Tiny Desk`,
    title: `WATCH: Masego's Personality And Talent Shines During NPR Tiny Desk Performance`,
    company: `VIBE Magazine`,
    date: `July 24, 2019`,
    url: `https://www.vibe.com/2019/07/masego-npr-tiny-desk-performance`,
  },
  {
    photo: `https://res.cloudinary.com/areese/image/upload/v1566154521/VIBE/Jidenna%20%22Tribe%22/PJ%20Morton%20%22Ready%22/pj-morton-ready.png`,
    photoCredit: `YouTube`,
    title: `WATCH: PJ Morton Teases Forthcoming 'PAUL' Album With New "Ready" Visual`,
    company: `VIBE Magazine`,
    date: `July 24, 2019`,
    url: `https://www.vibe.com/2019/07/pj-morton-teases-paul-album-shares-ready-video`,
  },
  {
    photo: `https://res.cloudinary.com/areese/image/upload/v1566154231/VIBE/Jidenna%20%22Tribe%22/Wale%20%22On%20Chill%22/Ari%20Lennox%20%22BMO%22/Hustler%27s%20Movie/hustlers-trailer.png`,
    photoCredit: `YouTube`,
    title: `WATCH: Cardi B, Jennifer Lopez, Lizzo, Keke Palmer And More Star in 'Hustlers' Trailer`,
    company: `VIBE Magazine`,
    date: `July 17, 2019`,
    url: `https://www.vibe.com/2019/07/hustlers-trailer-jennifer-lopez-cardi-b-keke-palmer`,
  },
  {
    photo: `https://static.vibe.com/files/2019/08/Mark-Denny-10-milion-NYC-1565979360-compressed.png`,
    photoCredit: `YouTube/112BK`,
    title: `Mark Denny Lands Nearly $10 Million In NYC Settlement After False Imprisonment`,
    company: `VIBE Magazine`,
    date: `August 16, 2019`,
    url: `https://www.vibe.com/2019/08/mark-denny-lands-nearly-10-million-in-nyc-settlement-after-false-imprisonment`,
  },
  {
    photoCredit: `Kevin Winter/Getty Images for Coachella`,
    photo: `https://static.vibe.com/files/2019/08/50-cent-snoop-dogg-1565973662-768x432.jpg`,
    title: `50 Cent Explains How Snoop Dogg "Stole" His Lamborghini`,
    company: `VIBE Magazine`,
    date: `August 16, 2019`,
    url: `https://www.vibe.com/2019/08/50-cent-snoop-dogg-stole-lamborghini`,
  },
  {
    photo: `https://static.vibe.com/files/2019/08/Screen-Shot-2019-08-15-at-1.17.19-PM-1565889456-compressed.png`,
    photoCredit: `Netflix`,
    title: `Christina Milian Provides All The Feels In Trailer For Netflix Romcom 'Falling Inn Love'`,
    company: `VIBE Magazine`,
    date: `August 15, 2019`,
    url: `https://www.vibe.com/2019/08/christina-milian-provides-all-the-feels-in-trailer-for-netflix-romcom-falling-inn-love`,
  },
  {
    photo: `https://static.vibe.com/files/2019/08/GettyImages-931520608-1565885668-1500x844.jpg`,
    photoCredit: `Ben Gabble/ Getty Images for The Association of Magazine Media`,
    title: `Don Lemon Sued Over Assault Allegations, CNN Defends News Anchor`,
    company: `VIBE Magazine`,
    date: `August 15, 2019`,
    url: `https://www.vibe.com/2019/08/don-lemon-sued-over-sexual-assault-allegations-cnn-defends-news-anchor`,
  },
  {
    photo: `https://static.vibe.com/files/2019/08/jay-z-nfl-1565726200-768x432.jpg`,
    photoCredit: `Brian Ach/ Getty Images for Something in the Water`,
    title: `Jayz-Z And Roc Nation Partner With The NFL`,
    company: `VIBE Magazine`,
    date: `August 13, 2019`,
    url: `https://www.vibe.com/2019/08/jay-z-roc-nation-partner-with-nfl`,
  },
  {
    photo: `https://static.vibe.com/files/2019/08/babygunna-1565381904-1500x844.jpg`,
    photoCredit: `Jason Koerner`,
    title: `Lil Baby Clears Up Rumors About Paying Gunna To Write His Raps`,
    company: `VIBE Magazine`,
    date: `August 9, 2019`,
    url: `https://www.vibe.com/2019/08/lil-baby-clears-up-rumors-about-paying-gunna-to-write-his-raps`,
  },
  {
    photo: `https://static.vibe.com/files/2019/08/Screen-Shot-2019-08-09-at-3.00.00-PM-1565377228-compressed.png`,
    photoCredit: `Parkwood Entertainment/H&M x Mantsho`,
    title: `Fashion Roundup: Beyoncé Drops Nostalgic Merch And H&M To Collab With South African Label Manthso`,
    company: `VIBE Magazine`,
    date: `August 9, 2019`,
    url: `https://www.vibe.com/2019/08/fashion-roundup-beyonce-drops-nostalgic-merch-and-hm-to-collab-with-south-african-label-mantsho`,
  },
  {
    photo: `https://static.vibe.com/files/2019/08/Screen-Shot-2019-08-09-at-1.49.25-PM-1565373003-e1565373108395-650x366.png`,
    photoCredit: `David Needleman`,
    title: `iLoveMakonnen ANd Big Freedia Talk Visibility In Hip-Hop In 'Billboard's Pride Issue`,
    company: `VIBE Magazine`,
    date: `August 9, 2019`,
    url: `https://www.vibe.com/2019/08/ilovemakonnen-and-big-freedia-talk-visibility-in-hip-hop-in-billboards-pride-issue`,
  },
  {
    photo: `https://static.vibe.com/files/2019/08/Screen-Shot-2019-08-09-at-12.06.06-PM-1565367827-650x366.png`,
    photoCredit: `Getty Images`,
    title: `Megan Thee Stallion Wants Fans To Stop Pitting Femcees Against Each Other`,
    company: `VIBE Magazine`,
    date: `August 9, 2019`,
    url: `https://www.vibe.com/2019/08/megan-thee-stallion-wants-fans-to-stop-pitting-femcees-against-each-other`,
  },
  {
    photo: `https://static.vibe.com/files/2019/08/mike-brown-sr-son-death-case-reopen-1-1565366636-1500x844.jpg`,
    photoCredit: `Video Screenshot`,
    title: `Mike Brown's Father Pleads For Prosecutor To Reopen Investigation Of Son's Death`,
    company: `VIBE Magazine`,
    date: `August 9, 2019`,
    url: `https://www.vibe.com/2019/08/mike-brown-father-reopen-death-investigation`,
  },
  {
    photo: `https://static.vibe.com/files/2019/08/Screen-Shot-2019-08-08-at-11.59.40-AM-1565280918-336x480.png`,
    photoCredit: `Twitter`,
    title: `Ari Lennox "BMO" Visual Puts A Smile On This Baby's Face`,
    company: `VIBE Magazine`,
    date: `August 8, 2019`,
    url: `https://www.vibe.com/2019/08/ari-lennoxs-bmo-visual-puts-a-smile-on-this-babys-face`,
  },
  {
    photo: `https://static.vibe.com/files/2019/08/GettyImages-1161742329-1565287760-1500x844.jpg`,
    photoCredit: `Jerritt Clark`,
    title: `T.I. And Killer Mike Extend Support To El Paso and Dayton Victims`,
    company: `VIBE Magazine`,
    date: `August 8, 2019`,
    url: `https://www.vibe.com/2019/08/t-i-and-killer-mike-extend-support-to-el-paso-and-dayton-victims`,
  },
  {
    photo: `https://static.vibe.com/files/2019/08/happy-jail-trailer-1565108462-e1565108800147-650x366.jpg`,
    photoCredit: `Netflix`,
    title: `Netflix's 'Happy Jail' Trailer Peels Back Layers Of Cebu's Dancing Inmates`,
    company: `VIBE Magazine`,
    date: `August 8, 2019`,
    url: `https://www.vibe.com/2019/08/netflixs-happy-jail-trailer-cebus-dancing-inmates`,
  },
  {
    photo: `https://static.vibe.com/files/2019/08/GettyImages-1067080692-1565276985-1500x844.jpg`,
    photoCredit: `Emma McIntyre/Getty Images for iHeartMedia`,
    title: `Khalid Announces Benefit Concert For El Paso Shooting Victims`,
    company: `VIBE Magazine`,
    date: `August 8, 2019`,
    url: `https://www.vibe.com/2019/08/khalid-announces-benefit-concert-for-el-paso-shooting-victims`,
  },
  {
    photo: `https://static.vibe.com/files/2019/08/unnamed-1-1565196344-e1565275499462-650x366.jpg`,
    photoCredit: `Courtesy of Ernest Rareberrg`,
    title: `Ernest Rarebergg's Mellow Aura Glows On "Calf"`,
    company: `VIBE Magazine`,
    date: `August 8, 2019`,
    url: `https://www.vibe.com/2019/08/ernest-rarebergg-second-single-calf`,
  },
  {
    photo: `https://static.vibe.com/files/2019/08/beyonce-smithsonian-1565193201-768x432.jpg`,
    photoCredit: `Gareth Cattermole/ Getty Images for Disney`,
    title: `Tyler Mitchell's Portrait Of Beyonce For 'Vogue' Acquired By Smithsonian`,
    company: `VIBE Magazine`,
    date: `August 7, 2019`,
    url: `https://www.vibe.com/2019/08/tyler-mitchell-beyonce-vogue-portrait-for-vogue-gallery-museum`,
  },
  {
    photo: `https://static.vibe.com/files/2019/08/GettyImages-106517326-1-1565193070-1500x844.jpg`,
    photoCredit: `Francois Durand/Getty Images`,
    title: `Barack Obama, Oprah And More Pay Homage To Toni Morrison`,
    company: `VIBE Magazine`,
    date: `August 7, 2019`,
    url: `https://www.vibe.com/2019/08/barack-obama-oprah-more-pay-homage-to-toni-morrison`,
  },
  {
    photo: `https://static.vibe.com/files/2019/08/lauryn-hill-her-show-1565119265-1500x844.jpg`,
    photoCredit: `Getty Images`,
    title: `Ms. Lauryn Hill And H.E.R. Announce Hollywood Bowl Show`,
    company: `VIBE Magazine`,
    date: `August 6, 2019`,
    url: `https://www.vibe.com/2019/08/lauryn-hill-her-hollywood-bowl-concert`,
  },
  {
    photo: `https://static.vibe.com/files/2019/08/GettyImages-1153777977-1565118426-1500x844.jpg`,
    photoCredit: `Frazer Harrison`,
    title: `Zendaya And Labrinth Score HBO 'Euphoria' Season Finale With New Song "All For Us"`,
    company: `VIBE Magazine`,
    date: `August 6, 2019`,
    url: `https://www.vibe.com/2019/08/zendaya-and-labrinth-score-hbo-euphoria-season-finale-with-new-song-all-for-us`,
  },
  {
    photo: `https://static.vibe.com/files/2019/08/Screen-Shot-2019-08-06-at-11.22.59-AM-1565105005.png`,
    photoCredit: `Getty Images`,
    title: `Former 'Rookie' Star Afton Williamson Has Angela Basset's Support Amid Allegations`,
    company: `VIBE Magazine`,
    date: `August 6, 2019`,
    url: `https://www.vibe.com/2019/08/former-rookie-star-afton-williamson-has-angela-bassets-support-amid-allegations`,
  },
  {
    photo: `https://static.vibe.com/files/2019/08/FCFA2550-2DBA-4CD5-A661-2E7728ACB919-1565017738-650x975.jpg`,
    photoCredit: `Nikko Lamere`,
    title: `FEATURES: Algee Smith Talks 'Euphoria,' The Art Of Manifestation And New Music`,
    company: `VIBE Magazine`,
    date: `August 5, 2019`,
    url: `https://www.vibe.com/2019/08/algee-smith-euphoria-season-finale-interview`,
  },
  {
    photo: `https://static.vibe.com/files/2019/08/jason-mitchell-the-chi-1565016630-768x432.jpg`,
    photoCredit: `Paras Griffin/Getty Images for Sony Pictures Entertainment`,
    title: `Jason Mitchell's Character's Fate Decided For 'The Chi' Season 3`,
    company: `VIBE Magazine`,
    date: `August 5, 2019`,
    url: `https://www.vibe.com/2019/08/jason-mitchell-the-chi-season-three`,
  },
  {
    photo: `https://static.vibe.com/files/2019/08/0-1565013026.png`,
    photoCredit: `Courtesy of Cult of Individuality`,
    title: `Cult Of Individuality Celebrates 10 Years Of Style And Innovation`,
    company: `VIBE Magazine`,
    date: `August 5, 2019`,
    url: `https://www.vibe.com/2019/08/cult-of-individuality-anniversary`,
  },
  {
    photo: `https://static.vibe.com/files/2019/08/GettyImages-1056152564-1564690830-1500x844.jpg`,
    photoCredit: `Jemal Countess/Getty Images for Women's Media Center`,
    title: `A.J. Calloway Fired From 'Extra' Gig After Sexual Assault Investigation`,
    company: `VIBE Magazine`,
    date: `August 1, 2019`,
    url: `https://www.vibe.com/2019/08/a-j-calloway-fired-from-extra-gig-after-sexual-assault-investigation`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/lil-nas-x-ties-record-billboard-1564512779-1500x844.jpg`,
    photoCredit: `Getty Images`,
    title: `Lil Nas X Plays Twitter CEO For A Day, And Yields Hilarious Results`,
    company: `VIBE Magazine`,
    date: `July 30, 2019`,
    url: `https://www.vibe.com/2019/07/lil-nas-x-twitter-ceo-video`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/mariah-carey-lil-nas-x-1564500233-1500x844.jpg`,
    photoCredit: `Getty Images`,
    title: `Mariah Carey Passes Hot 100 No. 1 To Lil Nas X`,
    company: `VIBE Magazine`,
    date: `July 30, 2019`,
    url: `https://www.vibe.com/2019/07/mariah-carey-lil-nas-x-old-town-road`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/cardi-b-bernie-sanders-1564498204-e1564498237447-768x432.jpg`,
    photoCredit: `Tommaso Boddi/Getty Images for Fashion Nova`,
    title: `Cardi B Sits Down With Senator Bernie Sanders`,
    company: `VIBE Magazine`,
    date: `July 30, 2019`,
    url: `https://www.vibe.com/2019/07/cardi-b-senator-bernie-sanders`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/lupus-la-mbjam19-event-1564442366-1500x844.jpg`,
    photoCredit: `Tiffany Rose/Getty Images for Lupus LA`,
    title: `Toni Braxton, Nick Cannon And More Attend Lupus LA And Michael B. Jordan's MBJAM 19`,
    company: `VIBE Magazine`,
    date: `July 29, 2019`,
    url: `https://www.vibe.com/2019/07/michael-b-jordan-toni-braxton-lupus-la-mbjam19`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/jay-z-woodstock-1564154263-768x432.jpg`,
    photoCredit: `Brian Ach/Getty Images for Something in the Water`,
    title: `Jay-Z Reportedly Cancels Woodstock 50 Festival Appearance`,
    company: `VIBE Magazine`,
    date: `July 26, 2019`,
    url: `https://www.vibe.com/2019/07/jay-z-reportedly-cancels-woodstock-50-festival-appearance`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/orange-is-the-new-black-foundation-1564152141-768x432.jpg`,
    photoCredit: `Dimitrios Kambouris/Getty Images`,
    title: `'Orange Is The New Black' Launches Foundation To Support Incarcerated Women`,
    company: `VIBE Magazine`,
    date: `July 26, 2019`,
    url: `https://www.vibe.com/2019/07/orange-is-the-new-black-criminal-justice-reform-fund`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/FellowsDJEnvyFonzworthChevy-1564089326-1500x844.jpg`,
    photoCredit: `Courtesy of Chevrolet`,
    title: `Chevrolet Buckles Up For Fourth Year Of Fellowship For HBCU Journalism Students`,
    company: `VIBE Magazine`,
    date: `July 25, 2019`,
    url: `https://www.vibe.com/2019/07/chevrolet-hbcu-journalism-students-tour`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/puerto-rico-gov-resigns-1564069917-768x432.jpg`,
    photoCredit: `Angel Valentin/Getty Images`,
    title: `Puerto Rico's Governor Ricardo Rosselló`,
    company: `VIBE Magazine`,
    date: `July 25, 2019`,
    url: `https://www.vibe.com/2019/07/puerto-ricos-governor-ricardo-rossello-resigns`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/jay-z-film-1564066311-768x432.jpg`,
    photoCredit: `Brian Ach/Getty Images for Something in the Water`,
    title: `Jay-Z To Produce Netflix Film 'The Harder They Fall'`,
    company: `VIBE Magazine`,
    date: `July 25, 2019`,
    url: `https://www.vibe.com/2019/07/jay-z-netflix-film-the-harder-they-fall`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/big-sean-new-music-1563999239-768x432.jpg`,
    photoCredit: `Tabatha Fireman/Getty Images`,
    title: `Big Sean Raps Through His Life Journey On New Single "Overtime"`,
    company: `VIBE Magazine`,
    date: `July 24, 2019`,
    url: `https://www.vibe.com/2019/07/big-sean-overtime-single`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/rocnation-logo-2017-billboard-1548-1563988391-e1563988511156-1500x844.jpg`,
    photoCredit: `Roc Nation`,
    title: `Roc Nation Announces Shari Bryant And Omar Grant As Co-Presidents`,
    company: `VIBE Magazine`,
    date: `July 24, 2019`,
    url: `https://www.vibe.com/2019/07/roc-nation-co-presidents-shari-bryant-omar-grant`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/bryson-tiller-new-song-1563984254-768x432.jpg`,
    photoCredit: `Issac Brekken/Getty Images for iHeartMedia`,
    title: `Bryson Tiller Releases "Blame" To Tease Upcoming Project`,
    company: `VIBE Magazine`,
    date: `July 24, 2019`,
    url: `https://www.vibe.com/2019/07/bryson-tiller-releases-blame-to-tease-upcoming-project`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/kid-cudi-kenya-barris-series-1563914016-1500x844.jpg`,
    photoCredit: `Getty`,
    title: `Kid Cudi And Kenya Barrris To Release Netflix Animated Music Series`,
    company: `VIBE Magazine`,
    date: `July 24, 2019`,
    url: `https://www.vibe.com/2019/07/kid-cudi-kenya-barris-netflix-animated-music-series`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/angela-davis-hall-of-fame-1563909698-1500x844.jpg`,
    photoCredit: `Getty Images`,
    title: `Dr. Angela Davis To Be Inducted Into The National Women's Hall Of Fame`,
    company: `VIBE Magazine`,
    date: `July 23, 2019`,
    url: `https://www.vibe.com/2019/07/angela-davis-inducted-national-womens-hall-of-fame`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/lil-nas-x-vma-nomination-2019-1563908027-1500x844.jpg`,
    photoCredit: `Getty Images`,
    title: `Lizzo, Lil Nas X, And H.E.R. Nominated For 2019 VMA 'Best New Artist'`,
    company: `VIBE Magazine`,
    date: `July 23, 2019`,
    url: `https://www.vibe.com/2019/07/lizzo-lil-nas-x-her-2019-vma-nomination`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/tiffany-haddish-netflix-1563897923-768x432.jpg`,
    photoCredit: `Dominik Bindl/Getty Images for Tribeca Film Festival`,
    title: `Tiffany Haddish To Host Stand-Up Comedy Series On Netflix`,
    company: `VIBE Magazine`,
    date: `July 23, 2019`,
    url: `https://www.vibe.com/2019/07/tiffany-haddish-to-host-stand-up-comedy-series-on-netflix`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/asap-rocky-mother-1563894382-768x432.jpg`,
    photoCredit: `Jonathan Leibson/Getty Images`,
    title: `A$AP Rocky's Mom Believes Sweden Is Targeting Her Son`,
    company: `VIBE Magazine`,
    date: `July 23, 2019`,
    url: `https://www.vibe.com/2019/07/asap-rocky-mother-statement-sweden`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/j-cole-dreamville-deluxe-album-1563812377-768x432.jpg`,
    photoCredit: `Tabatha Fireman/Getty Images`,
    title: `Dreamville Announces Deluxe Version Of 'Revenge Of The Dreamers III'`,
    company: `VIBE Magazine`,
    date: `July 22, 2019`,
    url: `https://www.vibe.com/2019/07/dreamville-deluxe-version-revenge-of-the-dreamers-three`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/GettyImages-510510836-1563807015-768x432.jpg`,
    photoCredit: `Frederick M. Brown/Getty Images`,
    title: `Earth, Wind, & Fire Make History For Kennedy Center Honors Recognition`,
    company: `VIBE Magazine`,
    date: `July 22, 2019`,
    url: `https://www.vibe.com/2019/07/earth-wind-fire-kennedy-center-honors`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/ayesha-steph-curry-eat-learn-play-foundation-launch-1563571832-1500x844.jpg`,
    photoCredit: `Steve Jennings/Getty Images for Eat. Learn. Play`,
    title: `Stephen And Ayesha Curry Launch Eat. Learn. Play Foundation In Oakland`,
    company: `VIBE Magazine`,
    date: `July 19, 2019`,
    url: `https://www.vibe.com/2019/07/stephen-ayesha-curry-eat-learn-play-foundation-event`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/donald-trump-asap-rocky-1563564258-650x366.jpg`,
    photoCredit: `Chip Somodevilla/ Getty Images`,
    title: `President Trump Speaks on A$AP Rocky's Detainment In Sweden`,
    company: `VIBE Magazine`,
    date: `July 19, 2019`,
    url: `https://www.vibe.com/2019/07/donald-trump-asap-rocky-statement`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/Ribbet-collage-1563552700-e1563552744483-768x432.jpg`,
    photoCredit: `Courtesy of Kumasi J. Barnett`,
    title: `Kumasi J. Barnett Flips Classic Comic Books To Highlight An Heroic Black Experience`,
    company: `VIBE Magazine`,
    date: `July 19, 2019`,
    url: `https://www.vibe.com/2019/07/kumasi-j-barnett-comic-book-black-revision`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/dear-white-people-trailer-1563553719-768x432.jpg`,
    photoCredit: `Michael Loccisano/Getty Images`,
    title: `Netflix Releases 'Dear White People' Season 3 Trailer`,
    company: `VIBE Magazine`,
    date: `July 19, 2019`,
    url: `https://www.vibe.com/2019/07/dear-white-people-season-three-trailer`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/nas-lost-tapes-2-1563552369-e1563553098284-768x432.jpg`,
    photoCredit: `Noam Galai/Getty Images`,
    title: `Nas Officially Drops 'The Lost Tapes 2'`,
    company: `VIBE Magazine`,
    date: `July 19, 2019`,
    url: `https://www.vibe.com/2019/07/nas-lost-tapes-2`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/GettyImages-1047209648-1563546808-e1563547884479-1500x844.jpg`,
    photoCredit: `Bennett Raglin/Getty Images for BET`,
    title: `Lil Baby Says He Previously Paid Guna To Write Raps He Practiced To`,
    company: `VIBE Magazine`,
    date: `July 19, 2019`,
    url: `https://www.vibe.com/2019/07/lil-baby-says-he-previously-paid-gunna-to-write-raps-he-practiced-to`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/GettyImages-1161993471-1563487698-1500x844.jpg`,
    photoCredit: `Gareth Cattermole/ Getty Images for Disney`,
    title: `Beyoncé Nears EGOT Status With Emmy Nominations for 'Homecoming'`,
    company: `VIBE Magazine`,
    date: `July 18, 2019`,
    url: `https://www.vibe.com/2019/07/beyonce-nears-egot-status-with-emmy-nominations-for-homecoming`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/unknown-t-murder-charge-1563476317-650x366.jpg`,
    photoCredit: `YouTube`,
    title: `UK Drill Rapper Unknown T Appears In Court Following Murder Charge`,
    company: `VIBE Magazine`,
    date: `July 18, 2019`,
    url: `https://www.vibe.com/2019/07/uk-drill-rapper-unknown-t-appears-in-court-following-murder-charge`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/GettyImages-1154530781-1563472917-1500x844.jpg`,
    photoCredit: `Tasos Katopodis/Getty Images for The Boris Lawrence Henson Foundation`,
    title: `Lalah Hathaway To Perform At Future x Sounds LA`,
    company: `VIBE Vixen`,
    date: `July 18, 2019`,
    url: `https://www.vibe.com/2019/07/lalah-hathaway-to-curate-art-exhibit-for-future-x-sounds-la`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/GettyImages-1157681109-1563397438-814x975.jpg`,
    photoCredit: `Jerritt Clark/Getty Images for Culture Creators`,
    title: `Melyssa Ford Shares The Importance Of Emotional Healing Following Traumatic Car Accident`,
    company: `VIBE Magazine`,
    date: `July 18, 2019`,
    url: `https://www.vibe.com/2019/07/melyssa-ford-healing-car-accident`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/shes-gotta-have-it-canceled-1563463905-768x432.jpg`,
    photoCredit: `Theo Wargo/Getty Images`,
    title: `Spike Lees 'She's Gotta Have It' Won't Return To Netflix For Season Three`,
    company: `VIBE Magazine`,
    date: `July 18, 2019`,
    url: `https://www.vibe.com/2019/07/spike-lees-shes-gotta-have-it-wont-return-to-netflix-for-season-three`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/chance-the-rapper-new-album-1563379328-768x432.jpg`,
    photoCredit: `Emma McIntyre/Getty Images`,
    title: `Chance The Rapper Reveals Details For Upcoming Debut Album`,
    company: `VIBE Magazine`,
    date: `July 17, 2019`,
    url: `https://www.vibe.com/2019/07/chance-the-rapper-debut-album-release-date`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/beyonce-lion-king-album-features-1563289412-768x432.jpg`,
    photoCredit: `Gareth Cattermole/Getty Images`,
    title: `Beyonce's 'Lion King' Soundtrack Features Blue Ivy, Jay-Z, Pharrell And More`,
    company: `VIBE Magazine`,
    date: `July 16, 2019`,
    url: `https://www.vibe.com/2019/07/beyonce-lion-king-album-childish-gambino-jay-z-kendrick-lamar`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/image003-2-1563220320-320x180.jpg`,
    photoCredit: `VEVO`,
    title: `R.LUM.R Shares Visuals For New Single "How This Feels"`,
    company: `VIBE Magazine`,
    date: `July 15, 2019`,
    url: `https://www.vibe.com/2019/07/r-lum-r-how-this-feels`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/lashana-lynch-007-1563215912-768x432.jpg`,
    photoCredit: `Slaven Vlasic/Getty Images`,
    title: `Lashana Lynch Reportedly Lands Role As First Black Woman '007'`,
    company: `VIBE Magazine`,
    date: `July 15, 2019`,
    url: `https://www.vibe.com/2019/07/lashana-lynch-007-james-bond-lead`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/50-cent-releases-remix-to-power-theme-song-1563214161-768x432.jpg`,
    photoCredit: `Roy Rochlin`,
    title: `50 Cent Releases 'Power' Theme Song Remix`,
    company: `VIBE Magazine`,
    date: `July 15, 2019`,
    url: `https://www.vibe.com/2019/07/50-cent-power-theme-song-remix`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/GettyImages-1151882549-1563216841-768x432.jpg`,
    photoCredit: `David Dee Delgado/Getty Images`,
    title: `Cardi B Rightfully Slams Lawyer For Obsessing Over Her Courtroom Attire`,
    company: `VIBE Magazine`,
    date: `July 15, 2019`,
    url: `https://www.vibe.com/2019/07/the-misogyny-towards-cardi-bs-courtroom-attire`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/Style-Kingdom-11-1563204456-e1563204604504-768x432.jpg`,
    photoCredit: `Bloomingdales`,
    title: `'The Lion King's Florence Kasumba Teams Up With Bloomingdales For Glam Pop-Up`,
    company: `Vibe Magazine`,
    date: `July 15, 2019`,
    url: `https://www.vibe.com/2019/07/bloomingdales-lion-king-florence-kasumba`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/michael-jackson-vibe-june-july-1995-header-1562952827-800x450.jpg`,
    title: `Michael Jackson's June/July 1995 Cover Story: 'ACTION JACKSON'`,
    company: `VIBE Magazine`,
    date: `July 14, 2019`,
    url: `https://www.vibe.com/featured/michael-jackson-june-july-1995-vibe-cover-story`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/wale-on-chill-song-jeremih-1562953531-e1562953966339-768x432.jpg`,
    photoCredit: `Shannon Finney/Getty Images`,
    title: `Wale Drops New Single "On Chill" With Jeremih`,
    company: `VIBE Magazine`,
    date: `July 12, 2019`,
    url: `https://www.vibe.com/2019/07/wale-jeremih-on-chill-song`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/idris-elba-fashion-brand-2hr-set-drop-2-7-1562947541-650x975.jpg`,
    photoCredit: `2HR`,
    title: `Idris Elba Brings Collaboration With 2Hr Set To North America`,
    company: `VIBE Magazine`,
    date: `July 12, 2019`,
    url: `https://www.vibe.com/2019/07/idris-elba-2hr-set-clothing-line-details`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/racists-1562944871-650x366.png`,
    photoCredit: `Howard County Police`,
    title: `Wifi Logins Help Identify Teens Who Vandalized High School With Racist And Anti-Semitic Slurs`,
    company: `VIBE Magazine`,
    date: `July 12, 2019`,
    url: `https://www.vibe.com/2019/07/wifi-logins-help-identify-teens-who-vandalized-high-school-with-racist-and-anti-semitic-slurs`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/euphoria-renewed-season-2-1562945204-768x432.jpg`,
    photoCredit: `Monica Schipper/Getty Images`,
    title: `HBO's 'Euphoria' Recieves Second Season Green Light`,
    company: `VIBE Magazine`,
    date: `July 12, 2019`,
    url: `https://www.vibe.com/2019/07/euphoria-zendaya-renewed-hbo`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/lil-wayne-blink-182-1562941872-e1562943292258-768x432.jpg`,
    photoCredit: `Cindy Ord/Getty Images`,
    title: `Lil Wayne Reportedly Threatens To Quit Blink-182 Tour`,
    company: `VIBE Magazine`,
    date: `July 12, 2019`,
    url: `https://www.vibe.com/2019/07/lil-wayne-reportedly-threatens-to-quit-blink-182-tour`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/kawhi-leonard-jaden-smith-1562874160-768x432.jpg`,
    photoCredit: `Getty Images `,
    title: `Kawhi Leonard And Jaden Smith Appear In New Balance 997 Campaign`,
    company: `VIBE Magazine`,
    date: `July 11, 2019`,
    url: `https://www.vibe.com/2019/07/kawhi-leonard-jaden-smith-new-balance-997-campaign`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/ja-rule-fyre-festival-1562862081-768x432.jpg`,
    photoCredit: `Gabe Ginsberg/Getty Images`,
    title: `Judge Clears Ja Rule Of Fyre Festival Fraud Claims`,
    company: `VIBE Magazine`,
    date: `July 11, 2019`,
    url: `https://www.vibe.com/2019/07/ja-rule-fyre-festival-fraud-claims-verdict`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/will-jada-smith-production-company-1562785961-768x432.jpg`,
    photoCredit: `Kevin Winter/Getty Images`,
    title: `Will And Jada Pinkett Smith Reveal Family Media Company, Westbrook Inc.`,
    company: `VIBE Magazine`,
    date: `July 10, 2019`,
    url: `https://www.vibe.com/2019/07/will-jada-pinkett-smith-westbrook-inc`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/unnamed-1562777114-477x480.png`,
    photoCredit: `Courtesy of Warner Records`,
    title: `DVSN Unveils New Tracks "Miss Me?" And "In Between"`,
    company: `VIBE Magazine`,
    date: `July 10, 2019`,
    url: `https://www.vibe.com/2019/07/dvsn-unveils-new-tracks-miss-me-and-in-between`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/ryan-destiny-joins-grownish-1562710134-768x432.jpg`,
    photoCredit: `Bennett Raglin/Getty Images`,
    title: `Ryan Desting Joining 'Grown-ish' Cast For Season 3`,
    company: `VIBE Magazine`,
    date: `July 9, 2019`,
    url: `https://www.vibe.com/2019/07/ryan-destiny-joins-grown-ish-season-3`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/halle-bailey-disney-1562692962-733x975.jpg`,
    photoCredit: `Frazer Harrison/Getty Images`,
    title: `Disney's Freeform Addresses Opposition To Halle Bailey's 'Little Mermaid' Role`,
    company: `VIBE Magazine`,
    date: `July 9, 2019`,
    url: `https://www.vibe.com/2019/07/disneys-freeform-addresses-opposition-to-halle-baileys-little-mermaid-role`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/diddy-mtv-making-the-band-reboot-1562682692-768x432.jpg`,
    photoCredit: `Brian Ach`,
    title: `Diddy Teases A Return Of MTV's 'Making The Band'`,
    company: `VIBE Magazine`,
    date: `July 9, 2019`,
    url: `https://www.vibe.com/2019/07/diddy-mtv-making-the-band`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/afrofuture-festival-controversy-1562608266-768x432.jpg`,
    photoCredit: `Gina Wetzler/Getty Images`,
    title: `A Detroit Music Festival Recieves Backlash For Charging White Attendees Double`,
    company: `VIBE Magazine`,
    date: `July 8, 2019`,
    url: `https://www.vibe.com/2019/07/afrofuture-youth-festival-white-tickets`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/big-freedia-ice-cream-1562604151-731x975.jpg`,
    photoCredit: `IIya S. Savenok/Getty Images`,
    title: `Big Freedia Announces Ben & Jerry's Ice Cream Flavor`,
    company: `VIBE Magazine`,
    date: `July 8, 2019`,
    url: `https://www.vibe.com/2019/07/big-freedia-announces-ben-jerrys-ice-cream-flavor`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/revenge-of-the-dreamers-iii-cover-art-1562357441-e1562357583204-768x432.jpg`,
    photoCredit: `Courtsey of Interscope`,
    title: `J.Cole And Dreamville's 'Revenge Of The Dreamers III' Has Arrived`,
    company: `VIBE Magazine`,
    date: `July 5, 2019`,
    url: `https://www.vibe.com/2019/07/revenge-of-the-dreamers-iii-album-stream`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/Screen-Shot-2019-07-04-at-11.25.34-AM-1562253954.png`,
    photoCredit: `Getty Images`,
    title: `Nas''Lost Tapes 2' Will Feature Production From Pharrell And No I.D.`,
    company: `VIBE Magazine`,
    date: `July 4, 2019`,
    url: `https://www.vibe.com/2019/07/nas-lost-tapes-2-pharrell-and-no-i-d`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/GettyImages-1152560626-1562247619-768x432.jpg`,
    photoCredit: `Tommaso Boddi/Getty Images`,
    title: `Marlon Wayans Denies Development Of 'White Chicks 2'`,
    company: `VIBE Magazine`,
    date: `July 4, 2019`,
    url: `https://www.vibe.com/2019/07/marlon-wayans-denies-development-of-white-chicks-2`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/Screen-Shot-2019-07-03-at-1.14.28-PM-1562174131-768x432.png`,
    photoCredit: `Getty Images`,
    title: `Rapsody's New Album To Feature Queen Latifah And D'Angelo`,
    company: `VIBE Magazine`,
    date: `July 3, 2019`,
    url: `https://www.vibe.com/2019/07/rapsodys-new-album-queen-latifah-dangelo`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/nicki-minaj-saudi-arabia-1562167252-768x432.jpg`,
    photoCredit: `Christopher Polk/Getty Images`,
    title: `Nicki Minaj To Perform At Saudi Arabia's Jeddah World Festival`,
    company: `VIBE Magazine`,
    date: `July 3, 2019`,
    url: `https://www.vibe.com/2019/07/nicki-minaj-jeddah-world-festival`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/j-cole-dreamville-two-new-songs-1562082880-768x432.jpg`,
    photoCredit: `Tabatha Fireman/Getty Images`,
    title: `J.Cole Offers Fans Two Tracks From 'Revenge Of The Dreamers III,' "Lambo Truck" And "Costa Rica"`,
    company: `VIBE Magazine`,
    date: `July 2, 2019`,
    url: `https://www.vibe.com/2019/07/j-cole-offers-fans-two-tracks-from-revenge-of-the-dreamers-iii-lambo-truck-and-costa-rica`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/GettyImages-579393876-1562003612-768x432.jpg`,
    photoCredit: `Bryan Steffy/Getty Images`,
    title: `Steve Harvey Covers Tuition For Eight College Freshmen`,
    company: `VIBE Magazine`,
    date: `July 1, 2019`,
    url: `https://www.vibe.com/2019/07/steve-harvey-covers-tuition-for-eight-college-freshmen`,
  },
  {
    photo: `https://static.vibe.com/files/2019/07/maleah-davis-sculpture-1561993540-768x432.jpg`,
    photoCredit: `Win McNamee/Getty Images`,
    title: `Maleah Davis To Be Honored Through Sculpture`,
    company: `VIBE Magazine`,
    date: `July 1, 2019`,
    url: `https://www.vibe.com/2019/07/maleah-davis-scultpture-texas`,
  },
  {
    photo: `https://static.vibe.com/files/2019/06/daniel-caesar-case-study-1-tour-1561751532-768x432.jpg`,
    photoCredit: `Getty Images`,
    title: `Daniel Caesar Announces Tour With Koffee`,
    company: `VIBE Magazine`,
    date: `June 29, 2019`,
    url: `https://www.vibe.com/2019/06/daniel-caesar-koffee-tour`,
  },
  {
    photo: `https://static.vibe.com/files/2019/06/GettyImages-1157881133-1-1561743102-768x432.jpg`,
    photoCredit: `Maury Phillips/Getty Images`,
    title: `Megan Thee Stallion Addresses Resurfaced Homophobic Tweets`,
    company: `VIBE Magazine`,
    date: `June 28, 2019`,
    url: `https://www.vibe.com/2019/06/megan-thee-stallion-addresses-resurfaced-homophobic-tweets`,
  },
  {
    photo: `https://static.vibe.com/files/2019/06/GettyImages-1138960699-1561735760-768x432.jpg`,
    photoCredit: `Rich Furry/Getty Images`,
    title: `Lizzo Says Her Stylists Were Assaulted By A Security Guard At Summerfest`,
    company: `VIBE Magazine`,
    date: `June 28, 2019`,
    url: `https://www.vibe.com/2019/06/lizzo-security-guard-racism-summerfest`,
  },
  {
    photo: `https://static.vibe.com/files/2019/06/doug-e-fresh-george-clinton-honor-1561646168-768x432.jpg`,
    photoCredit: `Jeff Hahne/Getty Images`,
    title: `Douf E. Fresh, George Clinton, And Gloria Gaynor To Be Honored At The National Museum Of African American Music`,
    company: `VIBE Magazine`,
    date: `June 27, 2019`,
    url: `https://www.vibe.com/2019/06/doug-e-fresh-george-clinton-national-museum-of-african-american-music`,
  },
  {
    photo: `https://static.vibe.com/files/2019/06/meek-mill-lids-co-owner-1561568762-e1561568835567-768x432.jpg`,
    photoCredit: `Mike Coppola/Getty Images`,
    title: `Meek Mill Becomes Co-Owner Of Lids`,
    company: `VIBE Magazine`,
    date: `June 26, 2019`,
    url: `https://www.vibe.com/2019/06/meek-mill-becomes-co-owner-of-lids`,
  },
  {
    photo: `https://static.vibe.com/files/2019/06/serena-williams-wheaties-1561498924-768x432.jpg`,
    photoCredit: `Getty Images`,
    title: `Serena Williams Graces The Cover Of Her First Wheaties Box`,
    company: `VIBE Magazine`,
    date: `June 25, 2019`,
    url: `https://www.vibe.com/2019/06/serena-williams-wheaties-box`,
  },
  {
    photo: `https://static.vibe.com/files/2019/06/IMG_2007_Facetune_13-06-2019-10-57-39-1-1561478188-e1561478448938-768x432.jpeg`,
    photoCredit: `AJ Jordan`,
    title: `Premiere: Aja Is Shaping Their Raps With Confident Candor On "I Ain't Left"`,
    company: `VIBE Magazine`,
    date: `June 25, 2019`,
    url: `https://www.vibe.com/2019/06/premiere-aja-is-shaping-their-raps-with-confident-candor-on-i-aint-left`,
  },
  {
    photo: `https://static.vibe.com/files/2019/06/travis-scott-cereal-1561477763-768x432.jpg`,
    photoCredit: `Theo Wargo/Getty Images`,
    title: `Travis Scott Partners With Reese's Puffs Cereal`,
    company: `VIBE Magazine`,
    date: `June 25, 2019`,
    url: `https://www.vibe.com/2019/06/travis-scott-reeses-puffs-cereal-box-design`,
  },
  {
    photo: `https://static.vibe.com/files/2019/06/unnamed-1561475853-768x768.jpg`,
    photoCredit: `Grandstand Media/RCA Records`,
    title: `A$AP Ferg Teams Up With City Girls And ANTHA On "Wigs"`,
    company: `VIBE Magazine`,
    date: `June 25, 2019`,
    url: `https://www.vibe.com/2019/06/aap-ferg-teams-up-with-city-girls-and-antha-on-wigs`,
  },
  {
    photo: `https://static.vibe.com/files/2019/06/ozuna-soulfrito-festival-1561403182-768x432.jpg`,
    photoCredit: `Getty Images`,
    title: `Ozuna To Headline 2019 Soulfrito Music Fest`,
    company: `VIBE Magazine`,
    date: `June 24, 2019`,
    url: `https://www.vibe.com/2019/06/ozuna-headline-2019-soulfrito-music-festival`,
  },
  {
    photo: `https://static.vibe.com/files/2019/06/missy-elliott-album-da-real-world-1561391390-e1561391757808-768x432.jpg`,
    photoCredit: `Craig Barritt/Getty Images`,
    title: `Missy Elliot Commemorates 20th Anniversary of 'Da Real World' Album`,
    company: `VIBE Magazine`,
    date: `June 24, 2019`,
    url: `https://www.vibe.com/2019/06/missy-elliott-da-real-world-album-anniversary`,
  },
  {
    photo: `https://static.vibe.com/files/2019/06/cardi-b-ascap-1561129932-650x975.jpg`,
    photoCredit: `Amy Sussman/Getty Images`,
    title: `Cardi B Recieves Huge Honor At ASCAP Rhythm & Soul Awards`,
    company: `VIBE Magazine`,
    date: `June 21, 2019`,
    url: `https://www.vibe.com/2019/06/cardi-b-ascap-rhythm-soul-awards`,
  },
  {
    photo: `https://static.vibe.com/files/2019/06/philadelphia-cops-racist-fb-posts-1561057021-768x432.jpg`,
    photoCredit: `Mark Makela`,
    title: `72 Philadelphia Cops Moved To Desk Duty After Racist Facebook Posts`,
    company: `VIBE Magazine`,
    date: `June 20, 2019`,
    url: `https://www.vibe.com/2019/06/72-philadelphia-cops-desk-duty-racist-facebook-post`,
  },
  {
    photo: `https://static.vibe.com/files/2019/06/Screen-Shot-2019-06-20-at-12.59.05-PM-1561050919-768x432.png`,
    photoCredit: `YouTube`,
    title: `Listen To An Unheard J Dilla Production On Common's "HER Love"`,
    company: `VIBE Magazine`,
    date: `June 20, 2019`,
    url: `https://www.vibe.com/2019/06/common-j-dilla-unheard-beat-her-love-listen`,
  },
  {
    photo: `https://static.vibe.com/files/2019/06/Screen-Shot-2019-06-20-at-12.21.39-PM-1561047742.png`,
    photoCredit: `Jamie McCarthy/Getty Images`,
    title: `Jada Pinkett Smith Admits Having A Threesome Once: "It's Not For Me"`,
    company: `VIBE Magazine`,
    date: `June 20, 2019`,
    url: `https://www.vibe.com/2019/06/jada-pinkett-smith-admits-having-a-threesome-once-its-not-for-me`,
  },
  {
    photo: `https://static.vibe.com/files/2019/06/GettyImages-1145112088-1560978294-732x975.jpg`,
    photoCredit: `David Becker/Getty Images`,
    title: `DaniLeigh To Bring Her Killer Dance Moves To Chicago's Ruido Fest`,
    company: `VIBE Magazine`,
    date: `June 20, 2019`,
    url: `https://www.vibe.com/2019/06/danileigh-to-perform-chicago-ruido-fest`,
  },
  {
    photo: `https://static.vibe.com/files/2019/06/GettyImages-993047140-1560976033-768x432.jpg`,
    photoCredit: `Tabatha Fireman/Getty Images`,
    title: `J.Cole's Brooklyn Day Party Nearly Sells Out With No Features`,
    company: `VIBE Magazine`,
    date: `June 19, 2019`,
    url: `https://www.vibe.com/2019/06/j-cole-day-party-new-york-details-sold-out`,
  },
  {
    photo: `https://static.vibe.com/files/2019/06/dear-white-people-season-3-premiere-date-1560957978-768x432.jpg`,
    photoCredit: `Michael Loccisano/Getty Images for SXSW`,
    title: `'Dear White People' Season 3 To Premiere Summer 2019`,
    company: `VIBE Magazine`,
    date: `June 19, 2019`,
    url: `https://www.vibe.com/2019/06/dear-white-people-season-3-premiere-date`,
  },
  {
    photo: `https://static.vibe.com/files/2019/06/meagen-thee-stallion-apple-music-doc-1560878575-1500x844.jpg`,
    photoCredit: `Bob Levey`,
    title: `Apple Music To Release Megan Thee Stallion Mini Doc`,
    company: `VIBE Magazine`,
    date: `June 18, 2019`,
    url: `https://www.vibe.com/2019/06/megan-thee-stallion-apple-music-doc`,
  },
  {
    photo: `https://static.vibe.com/files/2019/06/surviving-r-kelly-doc-mtv-awards-1560877572-e1560877654870-1500x844.jpg`,
    photoCredit: `Getty Images`,
    title: `'Surviving R.Kelly' Wins Best Doc At 2019 MTV Movie & TV Awards`,
    company: `VIBE Magazine`,
    date: `June 18, 2019`,
    url: `https://www.vibe.com/2019/06/surviving-r-kelly-best-documentary-mtv-awards-2019`,
  },
  {
    photo: `https://static.vibe.com/files/2019/06/GettyImages-958664554-1560811832-1500x844.jpg`,
    photoCredit: `Roy Rochlin/ Getty Images`,
    title: `'Empire' Actor Bryshere Y. Gray Arrested For Traffic Offense`,
    company: `VIBE Magazine`,
    date: `June 17, 2019`,
    url: `https://www.vibe.com/2019/06/empire-actor-bryshere-y-gray-arrested-for-traffic-offense`,
  },
  {
    photo: `https://static.vibe.com/files/2019/06/GettyImages-1146622328-1-1560809772-1500x844.jpg`,
    photoCredit: `Leon Bennett/Getty Images`,
    title: `T.I. Partners With Atlanta Church To End Mass Incarceration`,
    company: `VIBE Magazine`,
    date: `June 17, 2019`,
    url: `https://www.vibe.com/2019/06/t-i-partners-with-atlanta-church-to-end-mass-incarceration`,
  },
  {
    photo:
      'https://static.vibe.com/files/2019/06/GettyImages-1138696358-1560529952-650x366.jpg',
    photoCredit: 'Chip Somodevilla/Getty Images',
    title: `Street In Front Of NASA Headquarters Renamed To 'Hidden Figures Way'`,
    company: 'VIBE Magazine',
    date: 'June 14, 2019',
    url:
      'https://www.vibe.com/2019/06/street-in-front-of-nasa-headquarters-renamed-to-hidden-figures-way',
  },
  {
    photo:
      'https://static.vibe.com/files/2019/06/Ribbet-collage-1560528642-650x366.jpg',
    photoCredit: 'Getty Images',
    title: `Birdman and Benny Boom To Produce Indie Nigerian Film 'Tazmanian Devil'`,
    company: 'VIBE Magazine',
    date: 'June 14, 2019',
    url: 'https://www.vibe.com/2019/06/birdman-and-benny-boom-tazmanian-devil',
  },
  {
    photo:
      'https://static.vibe.com/files/2019/06/GettyImages-1153362021-1560525630-e1560526788814-650x366.jpg',
    photoCredit: 'Noam Galai/Getty Images',
    title: `Nas To Release Children’s Book, 'I Know I Can'`,
    company: 'VIBE Magazine',
    date: 'June 14, 2019',
    url: 'https://www.vibe.com/2019/06/nas-childrens-book-i-know-i-can',
  },
  {
    photo:
      'https://static.vibe.com/files/2019/06/good-burger-pop-up-shop-1560443898-768x432.jpg',
    photoCredit: 'Jeff Schear/Getty Images',
    title: `Nickelodeon Announces 'Good Burger' Pop-Up Restaurant`,
    company: 'VIBE Magazine',
    date: 'June 13, 2019',
    url:
      'https://www.vibe.com/2019/06/nickelodeon-good-burger-pop-up-restaurant',
  },
  {
    photo:
      'https://static.vibe.com/files/2019/06/goldlink-diaspora-album-stream-1560353383-e1560354070136-768x432.jpg',
    photoCredit: 'Paras Griffin/Getty Images',
    title: `GoldLink Does It Again: Listen To His New Project 'Diaspora'`,
    company: 'VIBE Magazine',
    date: 'June 12, 2019',
    url: 'https://www.vibe.com/2019/06/goldlink-diaspora-stream-album',
  },
  {
    photo:
      'https://static.vibe.com/files/2019/06/Omen_Revenge_Of_Dreamers-1560351854-768x432.jpg',
    photoCredit: 'Getty Images',
    title: `Dreamville Readies First 'ROTD3' Release With Omen, Dreezy, Ari Lennox And Ty Dolla $ign`,
    company: 'VIBE Magazine',
    date: 'June 12, 2019',
    url:
      'https://www.vibe.com/2019/06/dreamville-readies-first-rotd3-release-with-omen-dreezy-ari-lennox-and-ty-dolla-ign',
  },
  {
    photo:
      'https://static.vibe.com/files/2019/06/spike-lee-do-the-right-thing-1560266730-650x975.jpg',
    photoCredit: 'Mike Coppola/Getty Images',
    title: `Spike Lee's 'Do The Right Thing' Returns To Theaters For 30th Anniversary`,
    company: 'VIBE Magazine',
    date: 'June 11, 2019',
    url:
      'https://www.vibe.com/2019/06/spike-lee-do-the-right-thing-returns-to-theaters',
  },
  {
    photo:
      'https://static.vibe.com/files/2019/06/her-lights-out-festival-1560192753-e1560194427917-1500x844.jpg',
    photoCredit: 'Getty Images',
    title: `H.E.R. Announces She Will Headline And Curate Lights On Festival In September`,
    company: 'VIBE Magazine',
    date: 'June 10, 2019',
    url: 'https://www.vibe.com/2019/06/her-curates-lights-on-festival',
  },
  {
    photo:
      'https://static.vibe.com/files/2019/06/drake-chris-brown-collaboration-1560185360-768x432.png',
    photoCredit: 'Getty Images',
    title: `Drake On "No Guidance" With Chris Brown: "Wait Till The Video Drops"`,
    company: 'VIBE Magazine',
    date: 'June 10, 2019',
    url: 'https://www.vibe.com/2019/06/drake-chris-brown-new-song-stream',
  },
  {
    photo:
      'https://static.vibe.com/files/2019/06/GettyImages-463402080-1560015540-1500x844.jpg',
    photoCredit: 'Getty Images',
    title: `Louisiana Signs Law Allowing Expert Analysis On Eyewitness Identifications`,
    company: 'VIBE Magazine',
    date: 'June 8, 2019',
    url:
      'https://www.vibe.com/2019/06/louisiana-signs-law-that-allows-expert-analysis-on-eyewitness-identifications',
  },
  {
    photo:
      'https://static.vibe.com/files/2019/06/Weeksville-Brooklyn-video-1560004639-650x366.png',
    photoCredit: 'The Root',
    title: `The Fight To Save Brooklyn's Weeksville, A Black Historic Landmark, Continues`,
    company: 'VIBE Magazine',
    date: 'June 8, 2019',
    url: 'https://www.vibe.com/2019/06/what-you-need-know-about-weeksville',
  },
  {
    photo:
      'https://static.vibe.com/files/2019/06/D8agWGkWwAAjFzA-1559943827-975x975.jpg',
    photoCredit: 'Pandora',
    title: `Pandora Honors Lil Wayne, Nipsey Hussle, Mary J. Blige And Monica For Black Music Month`,
    company: 'VIBE Magazine',
    date: 'June 7, 2019',
    url:
      'https://www.vibe.com/2019/06/pandora-black-music-month-nipsey-hussle-lil-wayne-mary-j-blige-monica',
  },
  {
    photo:
      'https://static.vibe.com/files/2019/06/power-show-cast-1559941019-e1559942160776-1500x844.jpg',
    photoCredit: 'Getty Images',
    title: `Final Season Of Power To Premiere At Madison Square Garden`,
    company: 'VIBE Magazine',
    date: 'June 7, 2019',
    url:
      'https://www.vibe.com/2019/06/power-final-season-madison-square-garden-premiere',
  },
  {
    photo:
      'https://static.vibe.com/files/2019/06/sanaa-lathan-love-and-basketball-1559847599-768x432.jpg',
    photoCredit: 'Alberto E. Rodriguez/Getty Images',
    title: `Sanaa Lathan On Filming 'Love & Basketball:' "I Was Miserable"`,
    company: 'VIBE Magazine',
    date: 'June 6, 2019',
    url:
      'https://www.vibe.com/2019/06/sanaa-lathan-love-and-basketball-miserable',
  },
  {
    photo:
      'https://static.vibe.com/files/2019/06/GettyImages-1151257263-1559788977-1500x844.jpg',
    photoCredit: 'Aurelien Meunier/Getty Images',
    title: `Sorry Navy: Rihanna Didn't Rent Essex Island For New Album, Officials Say`,
    company: 'VIBE Magazine',
    date: 'June 5, 2019',
    url: 'https://www.vibe.com/2019/06/rihanna-did-not-rent-essex-island',
  },
  {
    photo:
      'https://static.vibe.com/files/2019/06/GettyImages-3309276-1559769753-e1559769909591-1500x844.jpg',
    photoCredit: 'Michael Fresco/Evening Standard/Getty Images',
    title: `Lee Daniels Developing Sammy Davis Jr. Miniseries With Tom Hanks`,
    company: 'VIBE Magazine',
    date: 'June 5, 2019',
    url: 'https://www.vibe.com/2019/06/sammy-davis-jr-lee-daniels-miniseries',
  },
]
