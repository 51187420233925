export const ColorScheme = {
  main: '#ff7212',
  mainSecond: '#4c3f5a',
  secondary: '#ff964f',
  secondarySecond: '#ffe4a3',
  white: '#fff',
  offWhite: '#f6f4f3',
  black: '#000',
  grey: '#e1e1e1',
  darkGrey: '#666',
  gradient:
    'background-image: linear-gradient(to right top, #ff964f, #ff8e42, #ff8534, #ff7c25, #ff7212)',
}

export const MOBILE_POST_HEIGHT = '400px'
export const TEXT_SHADOW = '0 2px 0 rgba(0, 0, 0, 0.07)'
export const BOX_SHADOW = '2px 4px 5px 0px rgba(0,0,0,0.10)'
export const IMAGE_BOX_SHADOW = '0 4px 8px 0 rgba(0,0,0,.2)'
export const BLACK_TRANSPARENT_BG = 'rgba(0, 0, 0, 0.8)'
export const ANIMATION_DELAY = '500ms ease'
export const POST_OVERLAY = 'rgba(0,0,0, 0.6)'
export const SEO_KEYWORDS = [
  `alexis`,
  `reese`,
  `journalism`,
  `newyork`,
  'video journalism',
  'new york',
  'nytimes',
  'craig newmark graduate school',
  'university',
  'metoo',
  'culture',
  'law',
  '#MeToo',
  'texas',
  'university of north texas',
  'nbc',
  'internship',
]
