/* eslint-disable quotes */
/* eslint-disable prettier/prettier */
import React from "react"
import styled from "styled-components"
import { graphql, StaticQuery } from "gatsby"
import { ColorScheme, ANIMATION_DELAY } from "../utils/Variables"
import { MAX_WIDTH } from "../utils/MediaQueries"
import Link, { StyledLinkAnchor } from "../utils/Link"
import NewsTicker from "./NewsTicker"
import PublishedArticles from "./PublishedArticles/Articles"

const email = "Alexis.Reese@icloud.com"

const StyledFooter = styled.footer`
  display: grid;
  grid-template-rows: 1fr auto;
  align-items: center;
  justify-items: center;
  min-height: 400px;
  margin: 20px 0 0 0;
  color: ${ColorScheme.offWhite};
  background-color: ${ColorScheme.black};
  padding: 10px;

  .Footer-innerGrid {
    display: grid;
    grid-template-columns: 2fr 1fr;
    width: 70%;
    padding-bottom: 10px;

    h2 {
      margin: 0;
      padding: 0;
      text-transform: uppercase;
      font-size: 0.8rem;
      color: rgba(255, 255, 255, 0.3);
      font-weight: normal;
      user-select: none;
      margin-bottom: 3px;
    }

    .Footer-nestedGrid {
      display: grid;
      grid-template-rows: repeat(2, 60px);
    }

    .socialMedia {
      align-self: center;
      justify-self: end;

      .socialMediaContainer {
        text-align: center;
      }

      p {
        color: rgba(255, 255, 255, 0.3);
        text-align: center;
        /* user-select: none; */
        text-transform: uppercase;
        font-size: 0.8rem;
        margin: 0;
        padding: 0;
      }
    }
  }

  .bottom {
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    width: 70%;
    padding: 10px 0 0 0;
    text-transform: uppercase;
    font-size: 0.8rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-content: center;

    .left {
      justify-self: start;
    }

    .right {
      justify-self: end;

      .madeWithLove {
        margin-left: 52px;
      }

      button {
        border: none;
        background: none;
        color: rgba(255, 255, 255, 0.3);
        padding: 0;
        margin: 0;
        text-transform: uppercase;
        cursor: pointer;
        margin-left: 149px;

        &:hover {
          color: ${ColorScheme.main};
          transition: ${ANIMATION_DELAY};
        }
      }
    }

    p {
      margin: 0;
      padding: 0;
    }

    .email {
      color: rgba(255, 255, 255, 0.3);
    }
  }

  @media (max-width: ${MAX_WIDTH}px) {
    .bottom {
      text-align: center;
      grid-template-columns: 1fr;

      .left,
      .right {
        justify-self: center;

        .copyright,
        .email a {
          font-size: 0.8rem !important;
        }

        button {
          display: none;
        }
      }
    }

    .Footer-innerGrid {
      grid-template-columns: 1fr;
      grid-gap: 10px;

      .Footer-nestedGrid {
        text-align: center;
        grid-gap: 10px;

        .activePosts,
        .shortBio {
          line-height: 1.3;
          font-size: 0.8rem;
        }
      }

      .socialMedia {
        justify-self: center;
      }
    }
  }
`

const Icon = styled.div`
  display: inline-block;

  span {
    color: rgba(255, 255, 255, 1);
    margin: 0 8px;
    cursor: pointer;
    font-size: 1.9rem;
    letter-spacing: 2px;
    transition: ${ANIMATION_DELAY};

    &:hover {
      color: ${({ hoverColor }) => hoverColor};
    }
  }

  @media (max-width: ${MAX_WIDTH}px) {
    span {
      font-size: 1.3rem;
    }
  }
`

const handleClick = () =>
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  })

const ShortBio = () => (
  <div>
    <h2>About</h2>
    Alexis Reese, an aspiring multimedia journalist —{" "}
    <Link color="#fff" hovercolor={ColorScheme.main} to="/about">
      read more
    </Link>
    .
  </div>
)

const socialMediaLinks = [
  {
    icon: "fab fa-linkedin-in",
    text: "Li",
    href: "https://www.linkedin.com/in/alexis-reese/",
    color: "#0577b2",
  },
  {
    icon: "fab fa-facebook-f",
    text: "Fb",
    href: "https://www.facebook.com/thealexisreese/",
    color: "#4267B2",
  },
  {
    icon: "fab fa-twitter",
    text: "Tw",
    href: "https://twitter.com/thealexisreese",
    color: "#1e95dc",
  },
  // {
  //   icon: 'fab fa-instagram',
  //   text: 'Ig',
  //   href: 'https://www.instagram.com/thealexisreese/',
  //   color: '#B13DAC',
  // },
]

const postsQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`

const Footer = () => (
  <StaticQuery
    query={postsQuery}
    render={data => {
      const posts = data.allMarkdownRemark.edges.map(({ node }) => {
        const { title } = node.frontmatter
        return (
          <Link
            color="#fff"
            hovercolor={ColorScheme.main}
            to={node.fields.slug}
            key={node.fields.slug}
          >
            <span>{title}</span>
          </Link>
        )
      })
      return (
        <StyledFooter>
          <div className="Footer-innerGrid">
            <div className="Footer-nestedGrid">
              <div className="shortBio">
                <ShortBio />
              </div>
              <div className="activePosts">
                <h2>News Ticker</h2>
                <NewsTicker news={posts} interval={5000} />
              </div>
              <div>
                <h2>Published Articles</h2>
                {PublishedArticles.length} Published Articles
              </div>
            </div>
            <div className="socialMedia">
              <p>Stay connected with Alexis</p>
              <div className="socialMediaContainer">
                {socialMediaLinks.map((social, idx) => (
                  <Icon hoverColor={social.color} key={idx}>
                    <StyledLinkAnchor href={social.href} target="_blank">
                      <span>{social.text}</span>
                    </StyledLinkAnchor>
                  </Icon>
                ))}
              </div>
            </div>
          </div>
          <div className="bottom">
            <div className="left">
              <p className="madeWithLove">Made with love in New York</p>
            </div>
            <div className="right">
              <button type="button" onClick={handleClick}>
                Top Of Page
              </button>
            </div>
          </div>
        </StyledFooter>
      )
    }}
  />
)

export default Footer
