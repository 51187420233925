import React from "react"
import styled from "styled-components"
import { MAX_WIDTH } from "../utils/MediaQueries"
import { ColorScheme, ANIMATION_DELAY } from "../utils/Variables"
import Link, { StyledLinkAnchor } from "../utils/Link"

const Nav = styled.nav`
  text-align: center;
  margin: auto;
  margin-bottom: 20px;
  background-color: ${ColorScheme.black};
  width: 40%;
  padding: 10px;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    color: ${ColorScheme.offWhite};
    display: inline;
    text-align: center;
    padding: 15px 20px;
    text-decoration: none;
    letter-spacing: 1px;
    transition: ${ANIMATION_DELAY};
  }

  li:hover {
    cursor: pointer;
    background-color: #fff;
    color: ${ColorScheme.black};
  }

  @media (max-width: ${MAX_WIDTH}px) {
    width: 100%;
    border-radius: 0;
  }
`

const NavBar = () => (
  <Nav>
    <ul>
      <Link
        to="/"
        color={ColorScheme.black}
        hovercolor={ColorScheme.mainSecond}
      >
        <li>Home</li>
      </Link>
      <Link
        to="/about"
        color={ColorScheme.black}
        hovercolor={ColorScheme.mainSecond}
      >
        <li>About</li>
      </Link>
    </ul>
  </Nav>
)

export default NavBar
