import React, { Component } from "react"
import styled from "styled-components"

const NewsItem = styled.div`
  display: ${({ isActive }) => (isActive ? "block" : "none")};
  width: 100%;
`

export default class NewsTicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      seconds: 0,
    }
  }

  componentDidMount() {
    const { interval } = this.props
    this.interval = setInterval(() => this._tick(), interval)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  _tick() {
    this.setState(prevState => ({
      seconds: prevState.seconds + 1,
    }))
  }

  renderNewsFeed() {
    const { seconds } = this.state
    const {
      news,
      news: { length },
    } = this.props
    return news.map((item, idx) => {
      return (
        <NewsItem key={idx} isActive={seconds % length === idx}>
          {item}
        </NewsItem>
      )
    })
  }

  render() {
    return this.renderNewsFeed()
  }
}
