import { Link } from "gatsby"
import styled from "styled-components"
import { ColorScheme, ANIMATION_DELAY } from "./Variables"
import { MAX_WIDTH } from "./MediaQueries"

export default styled(Link)`
  color: ${props => props.color};
  text-decoration: none !important;
  transition: ${ANIMATION_DELAY};

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    box-shadow: none;
  }

  &:hover {
    color: ${props =>
      props.hovercolor ? props.hovercolor : ColorScheme.offWhite};
  }
`

export const StyledLinkAnchor = styled.a`
  color: ${props => (props.color ? props.color : "#000")};
  text-decoration: none !important;
  display: inline-block;
  margin: ${props => (props.margin ? "0 20px" : "")};
  cursor: pointer;
  transition: ${ANIMATION_DELAY};

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    box-shadow: none;
  }

  &:hover {
    color: ${props => (props.hovercolor ? props.hovercolor : ColorScheme.main)};
  }

  @media (max-width: ${MAX_WIDTH}px) {
    /* margin: 0 5px; */
    font-size: 1rem !important;
  }
`
