import React from "react"
import { Link } from "gatsby"
import Footer from "./Footer"
import styled from "styled-components"
import { MAX_WIDTH } from "../utils/MediaQueries"
import { TEXT_SHADOW, ColorScheme } from "../utils/Variables"
import Nav from "./Nav"

const Header = styled.div`
  position: relative;
  user-select: none;
  text-align: center;

  h1 {
    margin: 0;
    padding-bottom: 0;
    letter-spacing: 1px;
    font-size: 4rem;
    letter-spacing: -3px;
    font-weight: 600;
    display: inline-block;
  }

  @media (max-width: ${MAX_WIDTH}px) {
    font-size: 3rem;
  }
`

const Slogan = styled.span`
  margin: 0;
  padding: 0;
  text-align: center;
  color: ${ColorScheme.black};
  letter-spacing: 5px;
  font-size: 15px;
  position: absolute;
  left: 225px;
  right: 0;
  font-weight: normal;

  @media (max-width: ${MAX_WIDTH}px) {
    font-size: 14px;
  }
`

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr auto;
  min-height: 100vh;
  background-color: #fff;
  color: ${ColorScheme.black};
  text-shadow: ${TEXT_SHADOW};
`

const LinkStyle = {
  color: "black",
  boxShadow: "none",
}

class Layout extends React.Component {
  render() {
    const { title, children } = this.props

    return (
      <Wrapper>
        <Header>
          <Slogan>all things</Slogan>
          <Link style={LinkStyle} to="/">
            <h1>{title}</h1>
          </Link>
        </Header>
        <Nav />
        {children}
        <Footer />
      </Wrapper>
    )
  }
}

export default Layout
